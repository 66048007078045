<template>
  <div
    class="program-card"
    @click="goToDetail(program['s.no.'])"
    :key="program['s.no.']"
    @mouseover="$emit('pauseInterval')"
    @mouseout="$emit('resumeInterval')"
  >
    <div class="program-card__left" v-if="showThumb">
      <img :src="program.imageid3after" alt="" />
    </div>
    <div :class="{ 'program-card__right': showThumb }">
      <div class="program-card__head">
        <h2 class="program-title">{{ program.title }}</h2>
        <div class="program-duration">
          इकाई
          <span>{{ englishToNepaliNumber(program.units) }} कपदा</span>
        </div>
      </div>
      <div class="program-card__body">
        <!-- <div class="program-date">
          <div class="program-date__start">
            <unicon name="calendar-alt" /> <span>शुरु भएको वर्ष</span>
            <span>{{ program.startyear }}</span>
          </div>
          <div class="program-date__end">
            <unicon name="calendar-alt" /> <span>अन्त्य भएको वर्ष</span>
            <span>{{ program.endyear }}</span>
          </div>
        </div> -->
        <!-- <div class="program-budget-progress">
              <progress-bar
                :progress="(achievement.expenditure / achievement.budget) * 100"
              ></progress-bar>
            </div> -->
        <div class="program-budget">
          कुल लागत
          <div class="program-budget__expenditure">
            रु. {{ formattedExpenditure }}
          </div>
        </div>
      </div>
      <div class="program-card__action" v-if="showActionLink">
        <a @click.prevent="moreDetail(program['s.no.'])"
          >थप जानकारी <unicon name="sign-out-alt"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { getFormatedNepaliNumber } from "../../helper/helper";
import { englishToNepaliNumber } from "nepali-number";
export default {
  name: "ProgramCard",
  components: {
    // eslint-disable-next-line
    progressBar: () => import("@/components/ProgressBar.vue"),
  },
  props: {
    program: { type: Object, required: true },
    showThumb: { type: Boolean, default: true, required: false },
    showActionLink: { type: Boolean, default: true, required: false },
    cardsSlideUp: { type: Boolean, required: false }
  },
  methods: {
    englishToNepaliNumber,
    goToDetail(id) {
      this.$emit("programCardClickHandler", id);
    },
    moreDetail(id) {
      this.$emit("moreDetailClickHandler", id);
    },
    getFormatedNepaliNumber
  },
  computed: {
    formattedExpenditure() {
      let expenditure = this.program.expenditure;
      expenditure = parseFloat(expenditure.replace(/,/g, ""));
      return getFormatedNepaliNumber(expenditure);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
